import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styled from "styled-components";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { InputText, TitleText } from "../styles";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase/firebase";

const Forgot = () => {
  const [error, setError] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <FormWrapper>
      <div
        className="w-full justify-between flex items-center mb-14"
        style={{ alignItems: "center" }}
      >
        <img src="/logo-nt.png" alt="logo" className="h-8" />
      </div>

      <TitleText className="title">
        Please enter your email to reset password.
      </TitleText>
      <Formik
        initialValues={{ email: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Please enter your email address";
            setError(true);
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Please enter a valid email address";
            setError(true);
          } else {
            setError(false);
          }

          return errors;
        }}
        onSubmit={async (values) => {
          setIsSubmitting(true);
          const { email } = values;

          await sendPasswordResetEmail(auth, email)
            .then((data) => {
              toast.success("Password reset sent successfully");
              if (data) {
                console.log(data);
              }
              values.email = "";
              setIsSubmitting(false);
            })
            .catch((error) => {
              if (error.message === "Firebase: Error (auth/user-not-found).") {
                toast.error("Credentials not found in our records.");
              }
              setIsSubmitting(false);
            });
        }}
      >
        {({ handleBlur }) => (
          <Form>
            <InputText>
              <label htmlFor="email">Email</label>
              <Input style={{ fontSize: "16px" }}>
                <Field
                  className="field"
                  type="text"
                  name="email"
                  placeholder="Enter address"
                />
                <ErrorMessage className="error" name="email" component="p" />
              </Input>
            </InputText>

            <button
              type="submit"
              className={error ? "button disabled" : "button submit"}
              disabled={isSubmitting || error}
              style={{ display: "grid", placeContent: "center" }}
            >
              {isSubmitting ? (
                <img
                  src="/svg-loaders/tail-spin.svg"
                  alt="loading"
                  width={24}
                  height={24}
                />
              ) : (
                <p>Reset</p>
              )}
            </button>

            <div className="links">
              <p>
                Don't have an account?
                <span>
                  <Link to="/register" className="link">
                    Create an account
                  </Link>
                </span>
              </p>

              <Link to="/login" className="link">
                <p>I remembered my password</p>
              </Link>
            </div>
          </Form>
        )}
      </Formik>
      <span className="span items-center flex mt-10">
        <a href="https://optimalmarkets.net" className="font-bold">
          Back to homepage
        </a>

        {/* <img src="/extraicons/back.svg" alt="back" className="h-5" /> */}
      </span>
    </FormWrapper>
  );
  // {
  //   loaded ? (

  //   ) : (
  //     "hello"
  //   );
  // }
};

const FormWrapper = styled.div`
  background-color: #0e121b;
  color: white;
  min-height: 100vh;
  font-size: 14px;
  display: grid;
  place-content: center;
  max-width: 100%;
  padding: 20px;

  .link {
    text-decoration: none;
    color: #1199fa;
    font-weight: 600;
  }

  .title {
    max-width: 360px;
    font-size: 24px;
    text-align: left;
    font-weight: 500;
    margin-bottom: 50px;
    line-height: 29px;

    @media screen and (max-width: 390px) {
      font-size: 20px;
      max-width: 280px;
    }
  }

  Form {
    box-sizing: border-box;
    /* width: max-content; */
    display: grid;
    text-align: left;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .field {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    height: 44px;
    background-color: #343b4a;
    /* border: ${(props) =>
      props.blur === true ? "1px solid blue" : "none"}; */
    border-radius: 0.25rem;
    outline: none;
    padding: 1rem;
    /* color: white; */
  }

  .error {
    color: #e64b60;
    margin: 0;
    height: fit-content;
  }

  input {
    transition: border 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
    padding: 0.5rem;
  }

  input:focus {
    border: 1px solid #1199fa;
  }

  input:invalid {
    border: 1px solid #e64b60;
  }

  input:valid {
    border: 1px solid #1199fa;
  }

  .links {
    display: grid;
    gap: 0.5rem;

    span {
      margin-left: 0.3rem;
    }
  }

  .button {
    cursor: pointer;
    height: 44px;
    font-size: 600;
    border: none;
    border-radius: 4px;
  }

  .submit {
    background-color: #1199fa;
    color: white;
  }

  .disabled {
    cursor: not-allowed;
    background-color: gray;
    user-select: none;
  }
`;

const Input = styled.div`
  display: grid;
  gap: 6px;
`;

export default Forgot;
