import Modal from "@mui/material/Modal";
import { Card, OutlinedBox, TitleText } from "../styles";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";
import ModalImage from "react-modal-image";
import { useState } from "react";
import toast from "react-hot-toast";
import emailjs from "@emailjs/browser";

const BillingModal = ({ open, user }) => {
  const { billing, setBilling } = open;
  const mobile = useMediaQuery("(max-width: 800px)");
  const [hasSelected, setHasSelected] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("");
  const [showSelected, setShowSelected] = useState("");
  const [options, setOptions] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [currentBalance, setCurrentBalance] = useState("");
  const [modeOfWithdrawal, setModeOfWithdrawal] = useState("");
  const [certificateCost, setCertificateCost] = useState("");
  const [total, setTotal] = useState("");
  const [amountDue, setAmountDue] = useState("");
  const [percent, setPercent] = useState("");

  const ofacOptions = [
    "Withdrawal Amount",
    "Mode of Withdrawal",
    "Certificate Cost",
  ];
  const insuranceOptions = ["Current Balance"];
  const stakingOptions = ["Total", "Amount Due"];

  const billingFormats = [
    {
      name: "Insurance",
      preview: "/billings/Insurance.png",
      options: insuranceOptions,
    },
    {
      name: "OFAC",
      preview: "/billings/Ofac.png",
      options: ofacOptions,
    },
    {
      name: "Staking",
      preview: "/billings/Staking.png",
      options: stakingOptions,
    },
  ];

  function handleSelected(name, optionList) {
    setHasSelected(true);
    setSelectedFormat(name);
    setOptions(optionList);
  }

  function handleFormatSelected() {
    setShowSelected(true);
  }

  function handleBalance(e) {
    const value = e.target.value;

    if (value) {
      setCurrentBalance(value);
    } else {
      setCurrentBalance("");
    }
  }

  function handleAmount(e) {
    const value = e.target.value;

    if (value) {
      setWithdrawalAmount(value);
    } else {
      setWithdrawalAmount("");
    }
  }

  function handleTotal(e) {
    const value = e.target.value;

    if (value) {
      setTotal(value);
    } else {
      setTotal("");
    }
  }

  function handleAmountDue(e) {
    const value = e.target.value;

    if (value) {
      setAmountDue(value);
    } else {
      setAmountDue("");
    }
  }

  function handleMode(e) {
    const value = e.target.value;

    if (value) {
      setModeOfWithdrawal(value);
    } else {
      setModeOfWithdrawal("");
    }
  }

  function handlePercent(e) {
    const value = e.target.value;

    if (value) {
      setPercent(value);
    } else {
      setPercent("");
    }
  }

  function handleCost(e) {
    const value = e.target.value;

    if (value) {
      setCertificateCost(value);
    } else {
      setCertificateCost("");
    }
  }

  async function sendBilling() {
    setIsSending(true);
    let base;
    switch (selectedFormat) {
      case "Insurance":
        base = `method: "Insurance",
            userName: ${user.firstname},
            userEmail: ${user.email},
            currentBalance: ${currentBalance},
            percent: ${percent}
            `;
        break;
      case "OFAC":
        base = `
          method: "OFAC",
          userName: ${user.firstname},
          userEmail: ${user.email},
          withdrawalAmount: ${withdrawalAmount},
          certificateCost: ${certificateCost},
          modeOfWithdrawal: ${modeOfWithdrawal},
          `;
        break;
      case "Staking":
        base = `
         method: "Staking",
        userName: ${user.firstname},
         userEmail: ${user.email},
         total: ${total},
         amountDue: ${amountDue},
        `;
        break;
      default:
        break;
    }

    const params = {
      details: `New ${selectedFormat} billing on Optimal Markets with details: ${base}`,
      action_name: "Billing",
      to_email: "themaarv@gmail.com",
      from_name: "Billing",
      logo_link: "https://optimalmarkets.net/logo.png",
      to_name: "BIG 20",
      broker_to_link: "https://app.optimalmarkets.net",
      broker_name: "Optimal Markets",
      // from_name: "Optimal Markets Notifications"
    };

    emailjs
      .send("service_x4dbltd", "template_kkyhhxq", params, "9IOr2_lHheTH7RW1k")
      .then(() => {
        console.log("sent");
        toast.success("Billing Sent Successfully.");
        setIsSending(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Failed to Send. Please try again later.");
        setIsSending(false);
      });
  }

  // async function sendBilling() {
  //   setIsSending(true);
  //   const url = "https://formats.globalmarketrades.com";

  //   let base;

  //   switch (selectedFormat) {
  //     case "Insurance":
  //       base = {
  //         method: "Insurance",
  //         userName: user.firstname,
  //         userEmail: user.email,
  //         site: "Optimal Markets",
  //         currentBalance,
  //       };
  //       break;
  //     case "OFAC":
  //       base = {
  //         method: "OFAC",
  //         userName: user.firstname,
  //         userEmail: user.email,
  //         site: "Optimal Markets",
  //         withdrawalAmount,
  //         currentBalance,
  //         modeOfWithdrawal,
  //       };
  //       break;
  //     case "Staking":
  //       base = {
  //         method: "Staking",
  //         userName: user.firstname,
  //         userEmail: user.email,
  //         site: "Optimal Markets",
  //         total,
  //         amountDue,
  //       };
  //       break;
  //     default:
  //       break;
  //   }

  //   const config = {
  //     method: "POST",
  //     mode: "cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(base),
  //   };

  //   await fetch(url, config)
  //     .then((response) => {
  //       if (response) {
  //         toast.success("Billing Sent Successfully.");
  //         setIsSending(false);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error("Failed to Send. Please try again later.");
  //       setIsSending(false);
  //     });
  // }

  return (
    <Modal
      open={billing}
      onClose={() => setBilling(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      {showSelected ? (
        <Card
          style={{
            placeSelf: "center",
            maxWidth: "360px",
          }}
        >
          <div className="top">
            <TitleText>Send {selectedFormat} Billing</TitleText>
            <button
              // className="close"
              style={{
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
              }}
              onClick={() => setBilling(false)}
            >
              <img
                src="/sidebaricons/close.svg"
                alt="close"
                className="close"
                height={10}
                width={10}
              />
            </button>
          </div>

          <ModalContent>
            <button
              style={{ maxWidth: "max-content" }}
              className="actionButton"
              onClick={() => setShowSelected(false)}
            >
              <p className="actionText">Go Back</p>
            </button>
            <div className="addcon">
              <label htmlFor="new">Client Name</label>
              <div className="add">
                <input
                  //   ref={cryptoAddressRef}
                  placeholder={user?.firstname}
                  defaultValue={user?.firstname}
                  //   onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>

            <div className="addcon">
              <label htmlFor="new">Client Email</label>
              <div className="add">
                <input
                  //   ref={cryptoAddressRef}
                  placeholder={user?.email}
                  defaultValue={user?.email}
                  //   onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>

            {selectedFormat === "Insurance" && (
              <div className="addcon">
                <label htmlFor="new">Current Balance</label>
                <div className="add">
                  <input
                    onChange={handleBalance}
                    type="number"
                    placeholder="0000"
                  />
                </div>
              </div>
            )}

            {selectedFormat === "Insurance" && (
              <div className="addcon">
                <label htmlFor="new">Percent</label>
                <div className="add">
                  <input
                    onChange={handlePercent}
                    type="text"
                    placeholder="15%"
                  />
                </div>
              </div>
            )}

            {selectedFormat === "Staking" && (
              <>
                <div className="addcon">
                  <label htmlFor="new">Total</label>
                  <div className="add">
                    <input
                      onChange={handleTotal}
                      type="number"
                      placeholder="0000"
                    />
                  </div>
                </div>

                <div className="addcon">
                  <label htmlFor="new">Amount Due</label>
                  <div className="add">
                    <input
                      onChange={handleAmountDue}
                      type="number"
                      placeholder="0000"
                    />
                  </div>
                </div>
              </>
            )}

            {selectedFormat === "OFAC" && (
              <div className="addcon">
                <label htmlFor="new">Withdrawal Amount</label>
                <div className="add">
                  <input
                    onChange={handleAmount}
                    type="number"
                    placeholder="0000"
                  />
                </div>
              </div>
            )}

            {selectedFormat === "OFAC" && (
              <div className="addcon">
                <label htmlFor="new">Mode of Withdrawal</label>
                <div className="add">
                  <input onChange={handleMode} placeholder="BTC" />
                </div>
              </div>
            )}

            {selectedFormat === "OFAC" && (
              <div className="addcon">
                <label htmlFor="new">Certificate Cost</label>
                <div className="add">
                  <input
                    onChange={handleCost}
                    type="number"
                    placeholder="0000"
                  />
                </div>
              </div>
            )}

            <button
              style={{
                width: "100%",
                placeContent: "center",
                display: "grid",
                marginTop: "15px",
              }}
              onClick={sendBilling}
              disabled={
                (selectedFormat === "Insurance" && !currentBalance) ||
                (selectedFormat === "OFAC" && !withdrawalAmount) ||
                (selectedFormat === "OFAC" && !modeOfWithdrawal) ||
                (selectedFormat === "OFAC" && !certificateCost) ||
                (selectedFormat === "Staking" && !total) ||
                (selectedFormat === "Staking" && !amountDue)
              }
              className={
                (selectedFormat === "Insurance" && !currentBalance) ||
                (selectedFormat === "OFAC" && !withdrawalAmount) ||
                (selectedFormat === "OFAC" && !modeOfWithdrawal) ||
                (selectedFormat === "OFAC" && !certificateCost) ||
                (selectedFormat === "Staking" && !total) ||
                (selectedFormat === "Staking" && !amountDue)
                  ? "button disabled"
                  : "button submit"
              }
            >
              {isSending ? (
                <img
                  src="/svg-loaders/tail-spin.svg"
                  alt="loading"
                  style={{
                    padding: "10px",
                    width: "20px",
                    height: "auto",
                  }}
                />
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>Send</p>
              )}
            </button>

            {/* <OutlinedBox>
              <p className="infot">
                Select WalletConnect if you can't see your wallet on the
                options. If it still isn't there, contact us through chat for
                inclusion.
              </p>
            </OutlinedBox> */}
          </ModalContent>
        </Card>
      ) : (
        <Card
          style={{
            placeSelf: "center",
            maxWidth: "360px",
          }}
        >
          <div className="top">
            <TitleText>Billing</TitleText>
            <button
              // className="close"
              style={{
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
              }}
              onClick={() => setBilling(false)}
            >
              <img
                src="/sidebaricons/close.svg"
                alt="close"
                className="close"
                height={10}
                width={10}
              />
            </button>
          </div>
          <ModalContent
            style={{ display: "grid", overflowX: "auto", overflowY: "hidden" }}
          >
            <div
              className="bills-scrollable scrollbar-hidden"
              style={{
                display: "flex",
                width: "100%",
                // overflowY: "hidden",
                placeSelf: "center",
                // backgroundColor: "red",
                padding: " 0 20px ",
                textAlign: "center",
                overflowX: "scroll",
                gap: "0.7rem",
              }}
            >
              {billingFormats.map((bill) => (
                <div
                  className="billingBox"
                  style={{
                    display: "grid",
                    gap: "0.5rem",
                    margin: "0 20px",
                  }}
                >
                  <p key={bill.name} className="textBold">
                    {bill.name}
                  </p>
                  <div className="billingGroup">
                    <div className="billingreveal">
                      <button className="billingButton">
                        <p style={{ color: "white" }}>Show</p>
                      </button>
                      <ModalImage
                        large={bill.preview}
                        alt={bill.name}
                        className="billingModal"
                      />
                    </div>
                    <img
                      src={bill.preview}
                      alt={bill.name}
                      onClick={() => handleSelected(bill.name, bill.options)}
                      className="billingImage"
                      style={{
                        objectFit: "contain",
                        borderRadius: "20px",
                        height: "250px",
                        border:
                          selectedFormat === bill.name
                            ? "1px solid #1199fa"
                            : "none",
                      }}
                    ></img>
                  </div>
                </div>
              ))}
            </div>

            <button
              disabled={!hasSelected || !selectedFormat}
              style={{
                maxWidth: "300px",
                placeSelf: "center",
                marginTop: "15px",
              }}
              onClick={handleFormatSelected}
              className={
                !hasSelected || !selectedFormat
                  ? "button disabled"
                  : "button submit"
              }
            >
              {/* {hasSelected ? (
                <img
                  src="/svg-loaders/tail-spin.svg"
                  alt="loading"
                  // width={16}
                  style={{
                    padding: "10px",
                    width: "20px",
                    height: "auto",
                  }}
                  // height={24}
                />
              ) : ( */}
              <p
                style={{
                  textDecoration: "none",
                  color: "white",
                  maxwidth: "max-content",
                  placeSelf: "center",
                }}
              >
                Select {selectedFormat}
              </p>
              {/* )} */}
            </button>
            <OutlinedBox>
              <a
                className="infot blue"
                href="https://t.me/x0xUnicorn"
                target="_blank"
              >
                Text me to add more or if you need help.
              </a>
            </OutlinedBox>
          </ModalContent>
        </Card>
      )}
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .box {
    background-color: #1f273a;
    border-radius: 4px;
    display: flex;
    place-content: center;
    cursor: pointer;
  }

  .option {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-weight: 600;
    place-self: center;
    padding: 0.5rem;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }

  .billingGroup {
    position: relative;
  }

  .billingreveal {
    position: absolute;
    right: 0;
    margin: 20px;
    width: max-content;
    display: grid;
    justify-items: center;
    align-items: center;
  }

  .billingButton {
    pointer-events: none;
    border-radius: 6px;
    border: none;
    outline: none;
    cursor: pointer;
    background: rgba(35, 165, 251, 0.671);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      color: #1199fa;
      padding: 0.5rem 1rem;
    }
  }

  .billingImage {
    opacity: 1;
  }

  .activeFormat {
    border: 2px solid red;
  }

  .billingModal {
    position: absolute;
    top: 0;
    opacity: 0;
    left: 0;
    height: 100%;
    display: flex;
    /* width: 100%; */
  }
`;

const ModalOption = styled.div``;

export default BillingModal;
