import { useEffect, useState } from "react";
import styled from "styled-components";
import { Menu, FeatureBtn, FeatureText } from "../styles";
import CountUp from "react-countup";
import { userContext } from "../context/userContext";
import { useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

const DepositCard = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const {  currentPrices, totalBalance, dispatch,  } = useContext(userContext);
  const [user, loading] = useAuthState(auth);
  const [deposits, setDeposits] = useState([]);

  function handleMenuOpen() {
    setMenuOpen(!menuOpen);
  }

  function switchAccount() {
    dispatch({
      type: "switchAccount",
      payload: "live",
    });
    setMenuOpen(false);
  }

  const [isReveal, setIsReveal] = useState(true);

  function handleReveal(e) {
    e.preventDefault();

    setIsReveal(!isReveal);
  }

  function getTotalDeposits(deposits) {
    let DepositTotal = 0;
    for (let i = 0; i < deposits.length; i++) {
      const depositAmount =
        currentPrices[deposits[i].type] *
        deposits[i].amount *
        currentPrices.USD;
      DepositTotal = DepositTotal + depositAmount;
    }
    setDeposits(DepositTotal);
  }

  useEffect(() => {
    const depositsList = [];
    // const withdrawalList = [];
    async function getDeposits() {
      const q = query(
        collection(db, "deposits"),
        where("user", "==", user.uid)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.data().status === "approved") {
          depositsList.push(doc.data());
        }
      });
      getTotalDeposits(depositsList);
    }

    // async function getWithdrawals() {
    //   const q = query(
    //     collection(db, "withdrawals"),
    //     where("user", "==", user.uid)
    //   );
    //   const querySnapshot = await getDocs(q);
    //   querySnapshot.forEach((doc) => {
    //     if (doc.data().status === "approved") {
    //       withdrawalList.push(doc.data());
    //     }
    //   });
    //   getTotalWithdrawals(withdrawalList);
    // }

    if (!loading && user) {
    //   setLoader(false);
      getDeposits();
    //   getWithdrawals();
    }
  }, [user, loading]);

  return (
    <Wrapper>
      <div className="left">
        <FeatureBtn>
          {/* <img src="/sidebaricons/assets.svg" alt="wallet" /> */}
          <img src="sidebaricons/deposit.svg" alt="deposits" />
        </FeatureBtn>
        <FeatureText>
          <span className="flex gap-1 items-center">
            <p className="topText smallText font-medium">Total Deposit</p>
            <button onClick={handleReveal} className="animate-pulse">
              <img
                src={isReveal ? "/eyes/reveal.svg" : "/eyes/hide.svg"}
                alt="show-password"
                width={13}
              />
            </button>
          </span>
          {isReveal ? (
            <CountUp
              end={deposits}
              duration={1}
              delay={0}
              separator=","
              prefix="$"
            >
              {({ countUpRef }) => (
                <div>
                  <span className="bottomText bigText" ref={countUpRef} />
                </div>
              )}
            </CountUp>
          ) : (
            <span className="bottomText bigText">*****</span>
          )}
        </FeatureText>
      </div>

      {/* <div className="right">
        {currentAccount ? (
          <button
            className={
              currentAccount === "practice" ? "switch practice" : "switch live"
            }
          >
            {currentAccount}
          </button>
        ) : (
          <button className="switch practice">
            Practice
          </button>
        )}

        <Menu
          onClick={switchAccount}
          className="switchMenu"
          style={{ display: menuOpen ? "flex" : "none" }}
        >
          Switch to {"live"} Account
        </Menu>
      </div> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
//   height: 149px;
  padding: 1rem 10px;
  padding-bottom: 1rem;
  border-radius: 10px;
  background: #131824;

  .left {
    display: flex;
    gap: 0.5rem;
  }

  .smallText {
    font-size: 13px;
  }

  .bigText {
    font-size: 20px;
  }

  .right {
    position: relative;

    button {
      cursor: pointer;
    }
  }

  .switchMenu {
    background-color: #1f273a;
    padding: 1rem;
    top: 23px;
    font-weight: 600;
    text-transform: capitalize;
    position: absolute;
    top: 30px;
    right: 0;
    border: 1px solid #19202f;
    cursor: pointer;
  }

  .switch {
    border: none;
    text-transform: capitalize;
    font-weight: 600;
    padding: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  .practice {
    color: #f7931a;
    background-color: #403529;
  }

  .live {
    color: #20bca4;
    background-color: #20383c;
  }

  .title {
    /* font-size: 1rem; */
  }

  .bigText {
    font-size: 20px;
  }
`;

export default DepositCard;
